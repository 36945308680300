import _ from 'lodash';
import { documentTypesEmission } from 'models/types';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');
/* eslint-disable */
export const updateObject = (oldObject, updatedProperties) => {
  return { ...oldObject, ...updatedProperties };
};

export const elapsedYears = (year) => {
  const currentYear = new Date().getFullYear();

  return currentYear - year;
};
const regex = /[(,!\s"#$%&/()=?'*[\]+{}`^,.\-;:_|~<>¡¿¬”"°\\aA-zZ·ªºñ]/g;

function validateUndefined(cond, firstAns, secondAns) {
  if (cond) {
    return firstAns;
  } else {
    return secondAns;
  }
}

function validateGender(cond1, cond2, firstAns, secondAns) {
  if (cond1) {
    return firstAns;
  } else if (cond2) {
    return secondAns;
  } else {
    return '';
  }
}

export const PersonformBody = (id, rucType, body) => {
  const dbPersonDate = new Date(`${body?.anio}/${Number(body?.mes) + 1}/${body?.dia}`);
  const dbPersonRucDate = new Date(
    `${body?.establishmentYear}/${Number(body?.establishmentMonth) + 1}/${body?.establishmentDay}`,
  );
  const dbPersonDriverRucDate = new Date(
    `${body?.driverBirthYear}/${Number(body?.driverBirthMonth) + 1}/${body?.driverBirthDay}`,
  );
  const generoTer = (gen) => {
    return validateGender(gen === true, gen === false, 'M', 'F');
  };
  if (id === '1' || (id === '2' && rucType === '1')) {
    return {
      person: {
        PrimerNombre: validateUndefined(
          body.primerNombrePersona === undefined,
          '',
          body.primerNombrePersona,
        ),
        PrimerApellido: validateUndefined(
          body.primerApellidoPersona === undefined,
          '',
          body.primerApellidoPersona,
        ),
        Genero: generoTer(body.gender),
        FechaNacimiento: validateUndefined(body.anio && body.dia, dbPersonDate, ''),
        EstadoCivil: validateUndefined(body.EstadoCivil === undefined, '', body.EstadoCivil),
        telefono: validateUndefined(body.contactCellPhone === undefined, '', body.contactCellPhone),
        correoElectronico: validateUndefined(body.email === undefined, '', body.email),
        correoElectronicoConfirma: validateUndefined(
          body.emailConfirm === undefined,
          '',
          body.emailConfirm,
        ),
        celular: validateUndefined(body.cellPhone === undefined, '', body.cellPhone),
        celularConfirma: validateUndefined(
          body.cellPhoneConfirm === undefined,
          '',
          body.cellPhoneConfirm,
        ),
        lugarMovilizacion: validateUndefined(body.Province === undefined, '', body?.Province),
        idDepartamento: validateUndefined(
          body.ProvinceDetail?.idDepartament === undefined,
          '',
          body.ProvinceDetail?.idDepartament,
        ),
        idCiudad: body.ProvinceDetail?.idCity === undefined ? '' : body.ProvinceDetail?.idCity,
        nombreCiudad: validateUndefined(
          body.ProvinceDetail?.nameCity === undefined,
          '',
          body.ProvinceDetail?.nameCity,
        ),

        nombreDepartamento: validateUndefined(
          body.ProvinceDetail?.nameDepartament === undefined,
          '',
          body.ProvinceDetail?.nameDepartament,
        ),

        departamentoCiudad: validateUndefined(
          body.ProvinceDetail?.province === undefined,
          '',
          body.ProvinceDetail?.province,
        ),
      },
    };
  }

  switch (id) {
    case '2':
      return {
        person: {
          razonSocial: validateUndefined(body.companyName, body.companyName, ''),
          fechaConstitucion: validateUndefined(
            body.establishmentYear && body.establishmentDay,
            dbPersonRucDate,
            '',
          ),

          actividadEconomica: validateUndefined(body.economicActivity, body.economicActivity, ''),
          correoElectronico: validateUndefined(body.email === undefined, '', body.email),
          correoElectronicoConfirma: validateUndefined(
            body.emailConfirm === undefined,
            '',
            body.emailConfirm,
          ),
          celular: validateUndefined(body.cellPhone === undefined, '', body.cellPhone),
          celularConfirma: validateUndefined(
            body.cellPhoneConfirm === undefined,
            '',
            body.cellPhoneConfirm,
          ),
          lugarMovilizacion: validateUndefined(body.Province === undefined, '', body?.Province),
          idDepartamento: validateUndefined(
            body.ProvinceDetail?.idDepartament === undefined,
            '',
            body.ProvinceDetail?.idDepartament,
          ),

          idCiudad: validateUndefined(
            body.ProvinceDetail?.idCity === undefined,
            '',
            body.ProvinceDetail?.idCity,
          ),
          nombreCiudad: validateUndefined(
            body.ProvinceDetail?.nameCity === undefined,
            '',
            body.ProvinceDetail?.nameCity,
          ),
          nombreDepartamento: validateUndefined(
            body.ProvinceDetail?.nameDepartament === undefined,
            '',
            body.ProvinceDetail?.nameDepartament,
          ),
          departamentoCiudad: validateUndefined(
            body.ProvinceDetail?.province === undefined,
            '',
            body.ProvinceDetail?.province,
          ),
          conductorTipoIdentificacion: validateUndefined(
            body.driverIdentificationType === undefined,
            '',
            body.driverIdentificationType,
          ),
          conductorNumeroIdentificacion: validateUndefined(
            body.driverIdentificationNumber === undefined,
            '',
            body.driverIdentificationNumber,
          ),
          conductorPrimerNombre: validateUndefined(
            body.driverFirstName === undefined,
            '',
            body.driverFirstName,
          ),
          conductorPrimerApellido: validateUndefined(
            body.driverLastName === undefined,
            '',
            body.driverLastName,
          ),
          conductorSegundoNombre: validateUndefined(
            body.driverMiddleName === undefined,
            '',
            body.driverMiddleName,
          ),
          conductorSegundoApellido: validateUndefined(
            body.driverSurName === undefined,
            '',
            body.driverSurName,
          ),
          conductorGenero: generoTer(body.driverGender),
          conductorFechaNacimiento: validateUndefined(
            body.driverBirthYear && body.driverBirthDay,
            dbPersonDriverRucDate,
            '',
          ),
          conductorEstadoCivil: validateUndefined(
            body.driverMaritalStatus === undefined,
            '',
            body.driverMaritalStatus,
          ),
        },
      };
    case '3':
      return {
        person: {
          primerNombre: validateUndefined(body.primerNombrePersona, body.primerNombrePersona, ''),
          primerApellido: validateUndefined(
            body.primerApellidoPersona,
            body.primerApellidoPersona,
            '',
          ),
          segundoNombre: validateUndefined(
            body.segundoNombrePersona,
            body.segundoNombrePersona,
            '',
          ),
          segundoApellido: validateUndefined(
            body.segundoApellidoPersona,
            body.segundoApellidoPersona,
            '',
          ),
          genero: generoTer(body.gender),

          fechaNacimiento: validateUndefined(body.anio && body.dia, dbPersonDate, ''),
          estadoCivil: validateUndefined(body.EstadoCivil === undefined, '', body.EstadoCivil),
          telefono: validateUndefined(
            body.contactCellPhone === undefined,
            '',
            body.contactCellPhone,
          ),
          correoElectronico: validateUndefined(body.email === undefined, '', body.email),
          correoElectronicoConfirma: validateUndefined(
            body.emailConfirm === undefined,
            '',
            body.emailConfirm,
          ),
          celular: validateUndefined(body.cellPhone === undefined, '', body.cellPhone),
          celularConfirma: validateUndefined(
            body.cellPhoneConfirm === undefined,
            '',
            body.cellPhoneConfirm,
          ),
          lugarMovilizacion: validateUndefined(body.Province === undefined, '', body?.Province),
          idDepartamento: validateUndefined(
            body.ProvinceDetail?.idDepartament === undefined,
            '',
            body.ProvinceDetail?.idDepartament,
          ),
          idCiudad: validateUndefined(
            body.ProvinceDetail?.idCity === undefined,
            '',
            body.ProvinceDetail?.idCity,
          ),
          nombreCiudad: validateUndefined(
            body.ProvinceDetail?.nameCity === undefined,
            '',
            body.ProvinceDetail?.nameCity,
          ),
          nombreDepartamento: validateUndefined(
            body.ProvinceDetail?.nameDepartament === undefined,
            '',
            body.ProvinceDetail?.nameDepartament,
          ),
          departamentoCiudad: validateUndefined(
            body.ProvinceDetail?.province === undefined,
            '',
            body.ProvinceDetail?.province,
          ),
        },
      };
    default:
      return {};
  }
};

const parseAccesories = (accessories, listAccesories = []) => {
  return accessories?.map((data) => {
    return {
      ...data,
      name: listAccesories?.find((dataAcc) => dataAcc.id == data.type)?.name,
      id: data?.type,
    };
  });
};

export const EmissionFormBody = (
  dataForm,
  policyCurrent,
  vehicleZerokm,
  ID,
  vehicleService,
  nombreColor,
  listAccesories,
) => {
  const body = {};
  body.ID = String(ID);
  body.VehiculoMotor = dataForm?.engine;
  body.VehiculoChasis = dataForm?.chassis;
  body.VehiculoColor = dataForm?.color;
  body.VehiculoColorDesc = nombreColor;
  body.VehiculoEmisionAccesorios = parseAccesories(dataForm?.accessories, listAccesories);
  body.VehiculoEmisionAccesoriosTotal = '';
  body.datosVehiculoServicio = vehicleService || false;
  if (!vehicleZerokm) {
    body.VehiculoEmisionInspeccion = !policyCurrent;
    if (policyCurrent) {
      body.VehiculoEmisionCompaniaAnterior = dataForm?.lastCompany;
      body.VehiculoEmisionFinVigenciaCompaniaAnterior = dataForm?.dateLastCompany;
      body.VehiculoEmisionCaratulaPoliza = { Ruta: '', Nombre: '' };
    } else {
      body.VehiculoEmisionPersonaContacto = dataForm?.InspectionContactPerson;
      body.VehiculoEmisionObservaciones = dataForm?.additionalRemark;
      body.VehiculoEmisionTelefonoContacto = dataForm?.ContactCellphone;
      body.VehiculoEmisionCorreoCliente = dataForm?.ClientEmail;
      body.VehiculoEmisionCorreoBroker = dataForm?.BrokerEmail;
    }
    return body;
  } else {
    body.ID = String(ID);
    body.VehiculoEmisionInspeccion = false;
    body.VehiculoEmisionFechaFactura = dataForm?.BillDate;
    body.VehiculoEmisionFechaSalidaConcesionario = dataForm?.DateCarDealer;
    body.VehiculoEmisionFactura = { Ruta: '', Nombre: '' };
    body.VehiculoEmisionCartaConcesionario = { Ruta: '', Nombre: '' };
    return body;
  }
};

export const formatNumberES = (n, d = 2) => {
  n = new Intl.NumberFormat('de-DE', { maximumFractionDigits: d }).format(parseFloat(n).toFixed(d));
  return n;
};
export const formatNumberUSD = (money = '') => {
  const clearData = `${money}`?.replace(/[^0-9]/g, '');
  const format = parseInt(+clearData).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  return format.replace(/,/g, '.').replace('$', '$ ');
};

export const parseReRate = (watch, planid, idAux, deducible) => {
  const codigoDeducible = deducible?.deductibles?.find(
    (currentDeductible) => currentDeductible.default,
  )?.codeDeducible;
  return {
    plan_id: planid,
    plans: [
      {
        id: planid,
        coverages: [
          {
            id: watch ? watch : idAux,
            checked: watch ? true : false,
            deducible: [],
            codigoDeducible,
          },
        ],
      },
    ],
    deducibleList: false,
  };
};

const toCurrency = (amount) => {
  return Math.trunc(amount)
    .toString()
    .replace(/(\d)(?=(\d{3})+)/g, '$1.');
};

export const parseDashboardItems = (data) => {
  return data.map((dashboard) => {
    const current = moment().startOf('day');
    const dateObject = moment(dashboard.FechaCreacion).startOf('day');
    let primaTotal;
    const dateLabel = moment(dashboard.FechaCreacion)
      .format('DD/MMM/YYYY HH:mm:ss')
      .replace('.', '');
    if (dashboard.CoberturaPlan === 'Basico') primaTotal = dashboard.CoberturaTotalPrimaBasico;
    if (dashboard.CoberturaPlan === 'Medio') primaTotal = dashboard.CoberturaTotalPrimaMedio;
    if (dashboard.CoberturaPlan === 'Full') primaTotal = dashboard.CoberturaTotalPrimaFull;
    primaTotal = primaTotal !== undefined ? primaTotal : 0;
    const primaTotalFormatted = primaTotal ? toCurrency(primaTotal) : undefined;

    return {
      fecha: dateLabel,
      cotizacion: dashboard.ID,
      conductor: dashboard.Nombre !== undefined ? dashboard.Nombre : '-',
      identificacion:
        dashboard.ClienteIdentificacion + ',' + dashboard.ClienteTipoIdentificacionDes,
      placa: dashboard.VehiculoPlacaNumero + ',' + dashboard.VehiculoPlacaTipoDes,
      vehiculo: (dashboard.VehiculoMarca || '-') + ',' + (dashboard.VehiculoModelo || ''),
      validez: Math.round(31 - moment.duration(current.diff(dateObject)).asDays()) + ' ' + 'días',
      prima: `$,${primaTotalFormatted},${dashboard.CoberturaPlan}`,
      acciones: {
        id: dashboard.ID,
        label: 'Retomar',
      },
    };
  });
};

export const parseEmissionDashboardItems = (data, userData) => {
  //capitalzie first letter and lower case the rest
  const validateBrokerCCorAgentRuc = (brokercc, agentRuc) => {
    if (_.toString(brokercc) === _.toString(agentRuc)) return brokercc;
    else return agentRuc;
  };
  const capitalizeFirstLetter = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  const returnPlateType = (type) => {
    if (type !== 'RAMV') {
      return capitalizeFirstLetter(type);
    }
    return type;
  };

  return data.map((dashboard) => {
    const current = moment().startOf('day');
    const dateObject = moment(dashboard.creationDate).startOf('day');
    const primaNoDec = JSON.stringify({
      valuePrima: `${dashboard.prima.total}`,
      planSele: `${dashboard.prima?.plan}`,
    });
    const user = userData || '{}';
    console.log('id', documentTypesEmission[dashboard.takerIdentification.type]?.name);
    return {
      cotizacion: dashboard?.id,
      clave: validateBrokerCCorAgentRuc(dashboard.brokerKey, user?.rucUser) || dashboard.brokerKey,
      tomador: `${_.capitalize(_.toLower(dashboard.takerName)) || '-'}`,
      identificacion:
        (dashboard.takerIdentification.id || '-') +
        ',' +
        (documentTypesEmission[dashboard.takerIdentification.type]?.name || '-'),
      placa: `${dashboard?.vehiclePlate.number},${returnPlateType(dashboard?.vehiclePlate.type)}`,
      vehiculo:
        `${dashboard?.vehicle?.brand}`.toUpperCase() +
        ',' +
        `${dashboard?.vehicle?.model}`.toUpperCase(),
      validez: Math.round(31 - moment.duration(current.diff(dateObject)).asDays()) + ' ' + 'días',
      estadoForm: dashboard?.formStatus,
      EstadoIns: dashboard?.inspectionStatus,
      poliza: dashboard?.policyNumber,
      fechaEmision: dashboard?.emissionDate,
      fechaVigencia: dashboard?.valididyDate,
      prima: primaNoDec || '',
      acciones: {
        label: 'Consultar',
        id: dashboard.id,
      },
    };
  });
};

export const quoteExpirationsMoment = () => {
  var startDate = moment().subtract(26, 'days').format('MM/DD/YYYY');
  var finishDate = moment().subtract(31, 'days').format('MM/DD/YYYY');
  return {
    startDate: startDate,
    finishDate: finishDate,
  };
};

export const qouteExpirationsRestart = () => {
  const startDate = moment().format('MM/DD/YYYY');

  return {
    startDate: startDate,
  };
};

export const FilterDatesParser = (date) => {
  const dateFrom = String(moment(date[0]).format('MM/DD/YYYY'));
  const dateTo = String(moment(date[1]).format('MM/DD/YYYY'));
  const dateChar = String(
    moment(date[0]).format('DD/MMM/YYYY').replace('.', '') +
      ' - ' +
      moment(date[1]).format('DD/MMM/YYYY').replace('.', ''),
  );
  const stringSplit = _.split(dateChar, '/', 5);
  const stringArray = _.map(stringSplit, (el) => _.capitalize(el));
  const dateCapitalized = _.join(stringArray, '/');
  return { dateFrom, dateTo, dateCapitalized };
};

export const billStartDate = () => {
  return moment().subtract(60, 'days');
};
export const currentDate = () => {
  return moment();
};

export const staticFilterParser = (date) => {
  const dateString = String(moment(date).format('DD/MMM/YYYY').replace('.', ''));
  const splitted = _.split(dateString, '/', 5);
  const stringDateArray = _.map(splitted, (el) => _.capitalize(el));
  return _.join(stringDateArray, '/');
};

export const formatDate = (date) => {
  const formatString = 'DD/MM/YYYY';
  if (!date) return;
  return moment(date, formatString);
};
export const momentFormatDate = (date) => {
  if (!date) return;
  return moment(date).format('DD/MMM/YYYY').replace('.', '');
};
export const handleName = (data) => {
  let name;
  if (data?.rucType === 'Juridico') {
    name = data?.businessName;
  } else {
    name =
      `${data?.firstName}` +
      ' ' +
      `${data?.secondName ? data?.secondName : ''}` +
      ' ' +
      `${data?.surname}` +
      ' ' +
      `${data?.secondSurname ? data?.secondSurname : ''}`;
  }
  return _.toLower(name);
};

export const splitDate = (date) => {
  if (date === undefined) return { dia: '', mes: '', anio: '' };
  const dia = new Date(date)?.getDate() || '';
  const mes = new Date(date)?.getMonth() != undefined ? new Date(date)?.getMonth() : '';
  const anio = new Date(date)?.getFullYear() || '';
  return { dia, mes, anio };
};

export const parseJsonReqRes = (reqResp) => {
  return reqResp ? JSON.stringify(JSON.parse(reqResp), null, 2) : '';
};

export const parseRequestEarnixDeductible = (deductible, planSelectedId) => {
  return {
    plan_id: planSelectedId,
    plans: [
      {
        id: planSelectedId,
        coverages: [
          {
            id: deductible.coverage_id,
            checked: true,
            codigoDeducible: deductible.codeDeducible, //deducible de la lista que se seleccionó.
            deducible: deductible.depend,
          },
        ],
      },
    ],
    deducibleList: true,
  };
};

export const textInspeccionData = {
  // Texto card pequeña
  textTitleCardNoRequire: (
    <p>
      NO REQUIERE
      <br /> INSPECCIÓN
    </p>
  ),
  textTitleCardRequire: (
    <p>
      REQUIERE
      <br /> INSPECCIÓN
    </p>
  ),
  textTitleCardApr: (
    <p>
      INSPECCIÓN
      <br /> APROBADA
    </p>
  ),
  textTitleCarRej: (
    <p>
      INSPECCIÓN
      <br /> RECHAZADA
    </p>
  ),
  textTitleCarPen: (
    <p>
      INSPECCIÓN
      <br /> PENDIENTE
    </p>
  ),
  // Texto Alerta
  textNoInspection: <p>Tu vehículo no requiere inspección</p>,
  textEstaInspeccion: <p>Su vehículo se encuentra en inspección</p>,
  textRejected: <p>Su vehículo se encuentra en revisión</p>,

  // Texto Descripcion azul
  textTitleCurrentPolicy: (
    <p>
      El vehículo es nuevo o posee una póliza vigente en
      <br /> otra compañía
    </p>
  ),
  textTitleRest: <p>Es todo de tu parte, nos encargaremos del resto</p>,
  textTitleApro: <p>La inspección de tu vehículo se aprobó exitosamente</p>,
  textTitleRepr: <p>La inspección de tu vehículo se encuentra en revisión</p>,

  // Texto Descripcion negra
  textDescCurrentPolicy: (
    <p>
      Puedes continuar con el proceso <br />
      de <b className="font-robotoBold">Emisión</b> de tu póliza
    </p>
  ),

  textDescRest: (
    <p>
      Una vez aprobada la inspección,
      <br /> podrás continuar con el proceso de <b className="font-robotoBold">emisión</b>
    </p>
  ),

  // Footer
  textDescNumber: (
    <p>
      Si tienes dudas o deseas averiguar sobre cómo va el proceso, comunícate desde Quito al 3932100
      o gratis desde cualquier parte del país al 1800542378
    </p>
  ),
};

export const textFVData = {
  textFormPendingSignature: <p>Formulario pendiente de firma</p>,
  textSuccessfullySignedForm: <p>Formulario firmado exitosamente</p>,
  textSuccessFirm: <p>El cliente realizó la firma del formulario con éxito</p>,
  textDanger: <p>El cliente no realizó la firma del formulario en el tiempo establecido</p>,
  textDangerInspection: <p>Su formulario se encuentra en revisión</p>,

  textTitleDoc: <p>En unos minutos se enviará un documento al cliente</p>,

  textTitleSuccess: <p>Puede continuar con la Emisión de la póliza</p>,
  textTitleDanger: (
    <p>
      Debe generarse una nueva cotización para
      <br /> continuar con la emisión de la póliza.
    </p>
  ),
  textTitleNewVehicle: <p>El vehículo es nuevo o posee una póliza vigente en otra compañía</p>,

  textDescDoc: (
    <p>
      Este documento debe ser <b className="font-robotoBold">firmado y enviado por email</b> para
      <br /> <b className="font-robotoBold">completar el proceso</b>
    </p>
  ),

  textFooterLarge: (
    <p>
      Te recordamos que tendrás cobertura, provisional por{' '}
      <b className="font-robotoBold">máximo siete (7) días a partir de hoy, </b>
      en los que debes completar el <b className="font-robotoBold">Formulario de Vinculación</b>,
      esta cobertura se materializa al finalizar el proceso de emisión. Si tienes dudas o deseas
      averiguar sobre cómo va el proceso, comunícate desde Quito al{' '}
      <b className="font-robotoBold">3932100 </b>o gratis desde cualquier parte del país al{' '}
      <b className="font-robotoBold">1800542378</b>
    </p>
  ),
  textFooterProcess: (
    <p>
      Si tienes dudas o deseas averiguar sobre cómo va el proceso,
      <br /> comunícate desde Quito al <b className="font-robotoBold">3932100</b> o gratis desde
      <br /> cualquier parte del país al <b className="font-robotoBold">1800542378</b>
    </p>
  ),
  textFooterSoport: (
    <p>
      Para obtener mas información del proceso comunícate con la mesa de ayuda al{' '}
      <b className="font-robotoBold">correo soporteautos@hdiseguros.com.ec</b>
    </p>
  ),
};

export const sumYear = (date) => {
  const year = parseInt(date.split('-')[0]) + 1;
  const month = date.split('-')[1];
  const day = date.split('-')[2];
  return `${year}-${month}-${day}`;
};
